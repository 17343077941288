"use client";

import SearchModal from "components/Blog/SearchModal";
import Image from "next/image";

type SectionCard = {
  icon: string;
  title: string;
  sectionId: string;
};

export default function NavigationSection() {
  const sectionCards: SectionCard[] = [
    { icon: "/assets/icons/blog-outline-16.svg", title: "Blog Posts", sectionId: "blog" },
    { icon: "/assets/icons/box-outline-16.svg", title: "Case Studies", sectionId: "casestudies" },
    { icon: "/assets/icons/bulb-61-outline-16.svg", title: "Ebooks", sectionId: "long-form-content" },
    { icon: "/assets/icons/users-outline-24.svg", title: "Community", sectionId: "community" },
    { icon: "/assets/icons/video-gallery-2-outline-16.svg", title: "Videos", sectionId: "youtube" },
    { icon: "/assets/icons/plug-2-outline-16.svg", title: "Integrations", sectionId: "integrations" },
    { icon: "/assets/icons/briefcase-25-outline-16.svg", title: "Documentation", sectionId: "docs" },
    { icon: "/assets/icons/calendar-2-outline-16.svg", title: "Events", sectionId: "events" },
  ];

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {" "}
      <div className="flex items-center justify-end pb-xs">
        <SearchModal searchType="all" />
      </div>
      <section className="mx-auto mb-lg rounded-md bg-secondary-light-50 p-md">
        <div className="grid grid-cols-2 gap-sm md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8">
          {sectionCards.map((card) => (
            <button
              key={card.sectionId}
              onClick={() => scrollToSection(card.sectionId)}
              className="group flex cursor-pointer flex-col items-center rounded-lg border border-secondary-light-100 bg-primary-neutral-50 p-xs transition-all duration-300 hover:border-primary-light-500 hover:shadow-md"
            >
              <span className="mb-xs text-3xl transition-transform duration-300 group-hover:scale-110">
                <Image src={card.icon} alt={card.title} width={32} height={32} />
              </span>
              <span className="text-center text-xs font-medium">{card.title}</span>
            </button>
          ))}
        </div>
      </section>
    </>
  );
}
