import clsx from "clsx";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateTemplateListingFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { cn } from "utils/functions";

const TemplateCard = ({ template }: { template: TemplateTemplateListingFragment }) => {
  const { templateImage: image, slug, title, excerpt, category } = template;
  const templateImage = getFragmentData(assetFragment, image);
  return (
    <Hyperlink
      href={`/template/${slug}`}
      className="group relative flex flex-col overflow-hidden rounded-lg bg-secondary-light-100/60 dark:bg-primary-dark-700"
    >
      {templateImage?.url && (
        <Image
          className="aspect-[1.6] w-full object-top"
          src={templateImage?.url || "Template Image"}
          alt={templateImage?.title || "Template image"}
          width={templateImage?.width || 278}
          height={templateImage?.height || 170}
          sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 30vw"
          quality={90}
          priority={false}
          loading="lazy"
        />
      )}
      <div className="flex flex-1 flex-col justify-between p-sm">
        <div>
          <div>
            <div className={clsx(typography.label, "mb-xs text-primary-light-500")}>{category}</div>
            <h3 className={clsx(typography.heading.cardHeading, "mb-3xs group-hover:underline")}>{title}</h3>
          </div>
          {excerpt && <p className={cn(typography.body.cardBody, "dark:text-primary-neutral-200")}>{excerpt}</p>}
        </div>
      </div>
    </Hyperlink>
  );
};

export default TemplateCard;
