import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import Image from "next/image";
import ComponentButton from "components/CtaLink";
import ColorizedText from "components/ColorizedText";
import type { FC } from "react";
import { TemplateBlogPostListingFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment, componentVideoFragment, metaSeoFragment } from "contentful/fragments";
import typography from "components/typography";
import clsx from "clsx";
import { cn, formatDate } from "utils/functions";
import Hyperlink from "components/Hyperlink";

const BlogHero = ({
  title,
  slug,
  seo,
  excerpt,
  video: videoFragment,
  category,
  author,
  publishDate,
}: TemplateBlogPostListingFragment) => {
  const metaSeo = getFragmentData(metaSeoFragment, seo);
  const openGraphImage = getFragmentData(assetFragment, metaSeo?.openGraphImage);
  const video = getFragmentData(componentVideoFragment, videoFragment);
  const videoAssets = [
    getFragmentData(assetFragment, video?.mediaCollection?.items[0]),
    getFragmentData(assetFragment, video?.mediaCollection?.items[1]),
  ];

  return (
    <Hyperlink
      href={`/blog/${slug}`}
      className="group flex flex-col lg:mb-lg lg:grid lg:grid-flow-col-dense lg:grid-cols-12 lg:gap-md 2xl:mb-xl"
    >
      <div className="relative transition-transform lg:col-span-6 lg:flex lg:items-center">
        {video ? (
          <video className="mt-10 aspect-[2.38] rounded-xl" width="100%" height="auto" muted autoPlay loop playsInline>
            <source src={videoAssets[0]?.url ?? ""} type="video/webm" />
            <source src={videoAssets[1]?.url ?? ""} type="video/mp4" />
          </video>
        ) : (
          <Image
            src={openGraphImage?.url || ""}
            alt={title || ""}
            className="h-full w-full rounded-xl object-cover"
            width={openGraphImage?.width || 832}
            height={openGraphImage?.height || 520}
            sizes="100vw"
            priority
            quality={90}
          />
        )}
      </div>
      <div className="space-y-sm py-sm text-left md:mx-auto lg:col-span-6">
        {category && (
          <div
            className={cn(
              "w-fit rounded-full bg-primary-light-50 px-xs pb-4xs pt-5xs text-xs font-medium leading-none text-primary-light-500",
            )}
          >
            {category}
          </div>
        )}
        {title && (
          <h2 className={clsx(typography.heading.longHeadline, "group-hover:underline")}>
            <ColorizedText heading={title} />
          </h2>
        )}
        {excerpt && <div className={cn(typography.body.componentBody, "text-primary-dark-500")}>{excerpt}</div>}
        {author && (
          <div className="flex items-center space-x-xs">
            {author?.headshot?.url ? (
              <div className="flex-shrink-0">
                <span>
                  <span className="sr-only">{author?.name}</span>
                  <Image
                    className="h-10 w-10 rounded-full object-cover"
                    src={author?.headshot?.url}
                    alt={author?.name || "Author image"}
                    width={40}
                    height={40}
                    quality={90}
                  />
                </span>
              </div>
            ) : null}
            <div>
              <div className="text-ellipsis text-sm font-medium">
                <span className="">{author?.name}</span>
              </div>
              <div className="flex space-x-1 whitespace-nowrap text-xs">
                {publishDate && <time dateTime={publishDate}>{formatDate(publishDate)}</time>}
              </div>
            </div>
          </div>
        )}
      </div>
    </Hyperlink>
  );
};

export default BlogHero;
