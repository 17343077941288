"use client";

import Image from "next/image";
import React, { useCallback, useEffect, useReducer, useRef, useState, useLayoutEffect } from "react";
import { ComponentLogoCloudFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment, entityCompanyFragment } from "contentful/fragments";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import clsx from "clsx";
import typography from "components/typography";
import { adjustImageDimensions, cn } from "utils/functions";

gsap.registerPlugin(ScrollTrigger);

const timeScaleClamp = gsap.utils.clamp(1, 1.75);

const LogoCloud = ({ heading, headingType, companyCollection }: ComponentLogoCloudFragment) => {
  // const marqueeRef = useRef<HTMLDivElement>(null);
  // const marqueeRef2 = useRef<HTMLDivElement>(null);
  const marqueeRef3 = useRef<HTMLDivElement>(null);
  const marqueeRef4 = useRef<HTMLDivElement>(null);
  // const tw = useRef<GSAPTween | null>(null);
  // const tw2 = useRef<GSAPTween | null>(null);
  const tw3 = useRef<GSAPTween | null>(null);
  const tw4 = useRef<GSAPTween | null>(null);
  // const tl = useRef<GSAPTimeline | null>(null);
  // const tl2 = useRef<GSAPTimeline | null>(null);
  const tl3 = useRef<GSAPTimeline | null>(null);
  const tl4 = useRef<GSAPTimeline | null>(null);
  const ctx = useRef<any>();

  let logos = companyCollection?.items || [];
  const midIndex = Math.ceil(logos.length / 2);
  const logosFirstHalf = logos.length > 8 ? logos.slice(0, midIndex) : logos;
  const logosSecondHalf = logos.length > 8 ? logos.slice(midIndex) : [];

  const processLogos = (logos: any) =>
    logos.map((item: any) => {
      const company = getFragmentData(entityCompanyFragment, item);
      const logo = getFragmentData(assetFragment, company?.logoOnLight ?? company?.logoIcon);
      const { width, height } = adjustImageDimensions({
        baseWidth: 42,
        scaleFactor: 0.75,
        width: logo?.width || 0,
        height: logo?.height || 0,
      });
      return { company, logo, width, height };
    });
  let logosFirstLine = logosFirstHalf ? processLogos(logosFirstHalf) : [];
  let logosSecondLine = logosSecondHalf ? processLogos(logosSecondHalf) : [];
  logosFirstLine = [...logosFirstLine, ...logosFirstLine, ...logosFirstLine, ...logosFirstLine];
  logosSecondLine = [...logosSecondLine, ...logosSecondLine, ...logosSecondLine, ...logosSecondLine];

  // let logos =
  //   companyCollection?.items?.map((item) => {
  //     const company = getFragmentData(entityCompanyFragment, item);
  //     const logo = getFragmentData(assetFragment, company?.logoOnLight ?? company?.logoIcon);
  //     const { width, height } = adjustImageDimensions({
  //       baseWidth: 42,
  //       scaleFactor: 0.75,
  //       width: logo?.width || 0,
  //       height: logo?.height || 0,
  //     });
  //     return { company, logo, width, height };
  //   }) || [];

  // logos = [...logos, ...logos, ...logos, ...logos];

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      // tl.current = gsap
      //   .timeline()
      //   .to(marqueeRef.current, {
      //     xPercent: -25,
      //     repeat: -1,
      //     duration: 74,
      //     ease: "none",
      //   })
      //   .totalProgress(0.5);
      // tl2.current = gsap
      //   .timeline()
      //   .to(marqueeRef2.current, {
      //     xPercent: -25,
      //     repeat: -1,
      //     duration: 74,
      //     ease: "none",
      //     reversed: true,
      //   })
      //   .totalProgress(0.5);
      tl3.current = gsap
        .timeline()
        .to(marqueeRef3.current, {
          xPercent: -25,
          repeat: -1,
          duration: 100,
          ease: "none",
          reversed: true,
        })
        .totalProgress(0.35);
      if (logosSecondLine.length > 0) {
        tl4.current = gsap
          .timeline()
          .to(marqueeRef4.current, {
            xPercent: -25,
            repeat: -1,
            duration: 100,
            ease: "none",
            reversed: true,
          })
          .totalProgress(0.35);
      }
      // tw.current = gsap.to(tl.current, { duration: 2, timeScale: 1, paused: true, ease: "power2.out" });
      // tw2.current = gsap.to(tl2.current, { duration: 2, timeScale: 1, paused: true, ease: "power2.out" });
      tw3.current = gsap.to(tl3.current, { duration: 2, timeScale: 1, paused: true, ease: "power2.out" });
      if (logosSecondLine.length > 0) {
        tw4.current = gsap.to(tl4.current, { duration: 2, timeScale: 1, paused: true, ease: "power2.out" });
      }
      ScrollTrigger.create({
        trigger: marqueeRef3.current,
        onUpdate: (self) => {
          let timescale = timeScaleClamp(Math.abs(self.getVelocity() / 200)) * self.direction;
          gsap.to(tl3.current, { duration: 1, timeScale: timescale, ease: "power2.out" });
          tw3.current?.invalidate().restart().timeScale(self.direction);
          if (logosSecondLine.length > 0) {
            gsap.to(tl4.current, { duration: 1, timeScale: timescale, ease: "power2.out" });
            tw4.current?.invalidate().restart().timeScale(self.direction);
          }
        },
      });
    }, [marqueeRef3]); // <- Scope!
    return () => ctx.current.revert(); // <- Cleanup!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx]);

  return (
    <>
      <div className="container mx-auto">
        <p
          className={cn(
            typography.heading.cardHeading,
            "mb-md text-center text-primary-dark-800/80 dark:text-primary-neutral-50/80",
          )}
        >
          {heading}
        </p>
      </div>
      {/* <div className="relative flex w-full items-center justify-start overflow-x-clip">
        <div className="flex -translate-x-1/4 whitespace-nowrap dark:invert" ref={marqueeRef} aria-hidden="true">
          <div className="flex w-full items-center justify-center">
            {logosFirstLine?.map(
              (
                { company, logo, width, height }: { company: any; logo: any; width: any; height: any },
                index: number,
              ) => {
                return (
                  <div
                    className="mx-sm flex items-center justify-center md:mx-md xl:mx-lg"
                    key={company?.sys.id + "-" + index}
                    style={{ width: `${width}px`, height: `${height}px` }}
                  >
                    <Image
                      className="object-contain object-center contrast-0 grayscale"
                      style={{ width: `${width}px`, height: `${height}px` }}
                      src={logo?.url || ""}
                      alt={company?.companyName || "Company Logo Cloud"}
                      height={height}
                      width={width}
                    />
                  </div>
                );
              },
            )}
          </div>
        </div>
        <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-r from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
        <div className="absolute right-0 top-0 h-full w-full bg-gradient-to-l from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
      </div>
      <div className="relative mt-md flex w-full items-center justify-start overflow-x-clip">
        <div className="flex -translate-x-1/4 whitespace-nowrap dark:invert" ref={marqueeRef2} aria-hidden="true">
          <div className="flex w-full items-center justify-center">
            {logosSecondLine?.map(
              ({ company, logo, width, height }: { company: any; logo: any; width: any; height: any }, index: any) => {
                return (
                  <div
                    className="mx-sm flex items-center justify-center md:mx-md xl:mx-lg"
                    key={company?.sys.id + "-" + index}
                    style={{ width: `${width}px`, height: `${height}px` }}
                  >
                    <Image
                      className="object-contain object-center contrast-0 grayscale"
                      style={{ width: `${width}px`, height: `${height}px` }}
                      src={logo?.url || ""}
                      alt={company?.companyName || "Company Logo Cloud"}
                      height={height}
                      width={width}
                    />
                  </div>
                );
              },
            )}
          </div>
        </div>
        <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-r from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
        <div className="absolute right-0 top-0 h-full w-full bg-gradient-to-l from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
      </div> */}
      <div className="relative flex w-full items-center justify-start overflow-x-clip">
        <div className="flex -translate-x-1/4 whitespace-nowrap dark:invert" ref={marqueeRef3} aria-hidden="true">
          <div className="flex w-full items-center justify-center max-md:scale-75">
            {logosFirstLine?.map(
              (
                { company, logo, width, height }: { company: any; logo: any; width: any; height: any },
                index: number,
              ) => {
                return (
                  <div
                    className="mx-sm flex items-center justify-center md:mx-md xl:mx-lg"
                    key={company?.sys.id + "-" + index}
                    style={{ width: `${width}px`, height: `${height}px` }}
                  >
                    <Image
                      className="object-contain object-center contrast-0 grayscale"
                      style={{ width: `${width}px`, height: `${height}px` }}
                      src={logo?.url || ""}
                      alt={company?.companyName || "Company Logo Cloud"}
                      height={height}
                      width={width}
                      quality={90}
                      priority={false}
                      loading="lazy"
                    />
                  </div>
                );
              },
            )}
          </div>
        </div>
        <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-r from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
        <div className="absolute right-0 top-0 h-full w-full bg-gradient-to-l from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
      </div>
      {logosSecondLine.length > 0 && (
        <div className="relative mt-md flex w-full items-center justify-start overflow-x-clip">
          <div className="flex -translate-x-1/4 whitespace-nowrap dark:invert" ref={marqueeRef4} aria-hidden="true">
            <div className="flex w-full items-center justify-center max-md:scale-75">
              {logosSecondLine?.map(
                (
                  { company, logo, width, height }: { company: any; logo: any; width: any; height: any },
                  index: any,
                ) => {
                  return (
                    <div
                      className="mx-sm flex items-center justify-center md:mx-md xl:mx-lg"
                      key={company?.sys.id + "-" + index}
                      style={{ width: `${width}px`, height: `${height}px` }}
                    >
                      <Image
                        className="object-contain object-center contrast-0 grayscale"
                        style={{ width: `${width}px`, height: `${height}px` }}
                        src={logo?.url || ""}
                        alt={company?.companyName || "Company Logo Cloud"}
                        height={height}
                        width={width}
                        quality={90}
                        priority={false}
                        loading="lazy"
                      />
                    </div>
                  );
                },
              )}
            </div>
          </div>
          <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-r from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
          <div className="absolute right-0 top-0 h-full w-full bg-gradient-to-l from-primary-neutral-50 to-10% dark:from-primary-dark-900"></div>
        </div>
      )}
    </>
  );
};

export default LogoCloud;
