import Image from "next/image";
import { ComponentTestimonialFragment } from "contentful/gql/graphql";
import { assetFragment, entityCompanyFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { adjustImageDimensions } from "utils/functions";

const LargeTestimonial = ({ quote, person, showCompany, showPerson }: ComponentTestimonialFragment) => {
  const personImage = getFragmentData(assetFragment, person?.headshot);
  const company = getFragmentData(entityCompanyFragment, person?.company);
  const companyLogo = getFragmentData(assetFragment, company?.logoIcon);
  const grayscaleLogo = getFragmentData(assetFragment, company?.logoOnDark);
  const { width, height } = adjustImageDimensions({
    baseWidth: 72,
    scaleFactor: 0.55,
    width: companyLogo?.width || 0,
    height: companyLogo?.height || 0,
  });

  return (
    <div>
      {showCompany ? (
        <div className="flex items-center justify-center">
          <div className="text-center">
            {companyLogo?.url ? (
              <Image
                className="object-contain contrast-200 grayscale invert"
                style={{ width: `${width}px`, height: `${height}px` }}
                src={companyLogo?.url}
                alt={companyLogo?.title || "Company logo"}
                height={height ?? 20}
                width={width ?? 20}
                quality={90}
                priority={false}
                loading="lazy"
              />
            ) : null}
            {company?.industries?.slice(0, 1).map((industry) => (
              <div
                key={industry}
                className="mx-auto mt-xs flex w-fit rounded-full bg-tertiary-pink-100 px-xs pb-4xs pt-5xs text-sm leading-none text-tertiary-pink-700"
              >
                {industry}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <blockquote className="relative text-justify font-medium text-primary-dark-800 dark:text-primary-dark-500">
        <span className="absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-tertiary-pink-500/20 blur-[200px]"></span>
        <span className="absolute -left-md -top-md text-[13rem] font-medium leading-none text-tertiary-pink-50 dark:text-tertiary-pink-500/30 md:text-[17rem]">
          &#8220;
        </span>
        <span className="absolute -bottom-xl -right-md text-[13rem] font-medium leading-none text-tertiary-pink-50 dark:text-tertiary-pink-500/30 md:-bottom-3xl md:text-[17rem]">
          &#8221;
        </span>
        <div className="relative z-10 px-lg py-md text-lg dark:font-normal dark:text-primary-neutral-200 md:px-xl md:py-lg md:text-2xl">
          {quote}
        </div>
        {showPerson ? (
          <footer className="ml-xl">
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                {personImage?.url ? (
                  <Image
                    className="h-md w-md rounded-full"
                    src={personImage?.url}
                    alt={personImage?.title || "Witness profile photo"}
                    height={personImage.height ?? 20}
                    width={personImage.width ?? 20}
                    quality={90}
                    priority={false}
                    loading="lazy"
                  />
                ) : (
                  <span className="bg-gray-100 inline-block h-14 w-14 overflow-hidden rounded-full">
                    <svg className="h-full w-full " fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                )}
              </div>

              <div className="flex text-base font-medium">
                <span className="text-primary-dark-100">
                  {person?.name}
                  {", "}
                </span>
                <span className="text-primary-neutral-200">{person?.role}</span>
              </div>
            </div>
          </footer>
        ) : null}
      </blockquote>
    </div>
  );
};

export default LargeTestimonial;
