"use client";

import { assetFragment, componentTestimonialFragment, entityCompanyFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentCarouselFragment } from "contentful/gql/graphql";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";

const Carousel = ({ autoplay, interval, itemsCollection, elementsPerPage }: ComponentCarouselFragment) => {
  const [carouselRef, carouselApi] = useEmblaCarousel(
    {
      loop: true,
      align: "start",
      containScroll: "trimSnaps",
    },
    autoplay ? [Autoplay({ delay: interval || 5000 })] : undefined,
  );

  const scrollTo = useCallback((index: number) => carouselApi && carouselApi.scrollTo(index, false), [carouselApi]);

  const scrollPrev = useCallback(() => {
    if (carouselApi) {
      carouselApi.scrollPrev();
    }
  }, [carouselApi]);

  const scrollNext = useCallback(() => {
    if (carouselApi) {
      carouselApi.scrollNext();
    }
  }, [carouselApi]);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onSelect = useCallback(() => {
    if (!carouselApi) {
      return;
    }
    setSelectedIndex(carouselApi.selectedScrollSnap());
  }, [carouselApi, setSelectedIndex]);

  useEffect(() => {
    if (!carouselApi) {
      return;
    }
    onSelect();
    setScrollSnaps(carouselApi.scrollSnapList());
    carouselApi.on("select", onSelect);
  }, [carouselApi, setScrollSnaps, onSelect]);

  const variant = elementsPerPage === 1 ? "single" : "multiple";

  let cols = "md:auto-cols-[100%]";
  if (elementsPerPage === 2) {
    cols = "md:auto-cols-[calc(50%-1.5rem)]";
  } else if (elementsPerPage === 3) {
    cols = "md:auto-cols-[calc(33%-1rem)]";
  } else if (elementsPerPage === 4) {
    cols = "md:auto-cols-[calc(25%-0.75rem)]";
  }

  return (
    <div className="relative">
      <div ref={carouselRef} className="overflow-hidden p-2 py-6">
        <div className={`grid auto-cols-[100%] grid-flow-col gap-12 ${cols}`}>
          {itemsCollection?.items &&
            itemsCollection.items.map((item) => {
              if (item?.__typename === "ComponentTestimonial") {
                const testimonial = getFragmentData(componentTestimonialFragment, item);
                const { quote, person } = testimonial;
                const personImage = getFragmentData(assetFragment, person?.headshot);
                const company = getFragmentData(entityCompanyFragment, person?.company);
                const companyLogo =
                  getFragmentData(assetFragment, company?.logoIcon) ??
                  getFragmentData(assetFragment, company?.logoOnLight);
                return (
                  <div key={testimonial.sys.id}>
                    <div className="bg-white h-full rounded-xl px-6 py-8 shadow-lg">
                      <blockquote className="flex h-full flex-col justify-between">
                        <div className="text-gray-900 lg:text-xl">
                          <p>{quote}</p>
                        </div>
                        <div className="mt-8">
                          <div className="font-bold">{person?.name}</div>
                          <div className="text-sm">
                            {person?.role}, {company?.companyName}
                          </div>
                          <Image
                            className="mt-2 h-6 w-auto lg:mt-4 lg:h-8"
                            src={companyLogo?.url || ""}
                            alt={companyLogo?.title || "Company logo"}
                            width={companyLogo?.width || 64}
                            height={companyLogo?.height || 32}
                            quality={90}
                            priority={false}
                            loading="lazy"
                          />
                        </div>
                      </blockquote>
                    </div>
                  </div>
                );
              }

              return null;
            })}
        </div>
      </div>
      {variant === "single" && (
        <>
          <button
            className="text-indigo-500/80 hover:text-indigo-700/80 bg-indigo-500/20 hover:bg-indigo-500/30 absolute left-2 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full p-1 shadow-sm transition-all will-change-transform active:scale-90 md:left-4"
            onClick={scrollPrev}
            aria-label="Previous"
          >
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            className="text-indigo-500/80 hover:text-indigo-700/80 bg-indigo-500/20 hover:bg-indigo-500/30 absolute right-2 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full p-1 shadow-sm transition-all will-change-transform active:scale-90 md:right-4"
            onClick={scrollNext}
            aria-label="Next"
          >
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
          <div className="mt-8 flex justify-center gap-4">
            {scrollSnaps.map((_, index) => (
              <button
                className={`bg-gray-400 h-2 w-2 rounded-full transition will-change-transform ${
                  index === selectedIndex ? "bg-indigo-700 scale-125" : ""
                }`}
                key={_}
                type="button"
                aria-label={`Go to slide ${index + 1}`}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Carousel;
