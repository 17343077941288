"use client";

import typography from "components/typography";
import { assetFragment, entityCompanyFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentTestimonialFragment } from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Image from "next/image";
import { useLayoutEffect, useRef } from "react";
import { cn } from "utils/functions";

const SplitTextToSpans = ({ text }: { text: string }) => {
  const wordArray = text.split(" ");
  return wordArray.map((word, wordIndex) => {
    const letterArray = word.split("");
    return (
      <span key={"word" + wordIndex} className="inline-block">
        {letterArray.map((letter, letterIndex) => (
          <span
            key={"word" + wordIndex + "letter" + letterIndex}
            className="word inline-block opacity-20 transition-opacity"
          >
            {letter === " " ? "\u00A0" : letter}
          </span>
        ))}
        {"\u00A0"}
      </span>
    );
  });
};

// const SplitTextToSpans = ({ text }: { text: string }) => {
//   const textArray = text.split(' ');
//   return textArray.map((word, index) => (
//     <span key={index}>
//       <span className="word inline-block opacity-10 transition-opacity duration-300">{word}</span>
//       <span> </span>
//     </span>
//   ));
// };

const ScrollInteractiveTestimonial = (props: ComponentTestimonialFragment) => {
  const person = props.person;
  const personImage = getFragmentData(assetFragment, person?.headshot);
  const company = getFragmentData(entityCompanyFragment, person?.company);
  const companyLogo = getFragmentData(assetFragment, company?.logoIcon);
  const tw = useRef<GSAPTween | null>(null);
  const tl = useRef<GSAPTimeline | null>(null);
  const ctx = useRef<any>();
  const quoteRef = useRef<HTMLParagraphElement>(null);

  useLayoutEffect(() => {
    ctx.current = gsap.context((self) => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: quoteRef.current,
          start: "top 80%",
          end: "top 70%",
          scrub: 3,
        },
      });
      const letters = self.selector && self.selector(".word");
      letters.forEach((word: any) => {
        tl.current?.to(word, {
          opacity: 1,
          duration: 0.5,
          stagger: 0.5,
        });
      });
      tl.current?.pause();
      gsap
        .timeline({
          scrollTrigger: {
            trigger: quoteRef.current,
            start: "top 80%",
            end: "bottom 60%",
            scrub: 1,
          },
        })
        .to('[data-gsap-element="quote-border"]', {
          scaleY: "300%",
          duration: 0.5,
        });
    }, quoteRef); // <- Scope!
    return () => ctx.current.revert(); // <- Cleanup!
  }, [ctx]);

  return (
    <div className="flex text-primary-dark-800 dark:text-primary-neutral-50" ref={quoteRef}>
      <div
        data-gsap-element="quote-border"
        className="mr-sm h-lg origin-top border-l-2 border-primary-dark-900/60 dark:border-primary-neutral-50/60 lg:mr-[30px]"
      ></div>
      <blockquote>
        <div className="mb-sm">
          <p className={cn(typography.body.featureText, "font-medium")}>
            <span className="word inline-block opacity-20 transition-opacity">&ldquo;</span>
            {SplitTextToSpans({ text: props.quote ?? "" })}
            <span className="word inline-block opacity-20 transition-opacity">&rdquo;</span>
          </p>
        </div>
        <footer className="inline-flex items-center space-x-xs rounded bg-avatar-light px-xs py-xs dark:bg-avatar-dark md:px-sm md:py-2xs">
          {personImage?.url && (
            <Image
              className="h-[30px] w-[30px] rounded-full lg:h-md lg:w-md"
              src={personImage?.url}
              alt={personImage?.title || "Testimonial profile photo"}
              width={personImage?.width || 40}
              height={personImage?.height || 40}
              quality={90}
              priority={false}
              loading="lazy"
            />
          )}
          <div className="flex items-center text-xs md:text-sm">
            <div>{person?.name}</div>
            <svg className="mx-[3px] h-sm w-sm" fill="currentColor" viewBox="0 0 20 20">
              <path d="M11 0h3L9 20H6l5-20z" />
            </svg>
            <div>
              {person?.role}, {company?.companyName}
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  );
};

export default ScrollInteractiveTestimonial;
