import { ComponentCardFragment } from "contentful/gql/graphql";
import Image from "next/image";
import Link from "next/link";

import clsx from "clsx";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import richTextParser, { RichText } from "utils/richTextParser";
import Hyperlink from "components/Hyperlink";
import Icon from "components/Icon";

const PillCard = ({ heading, icon: iconFragment, body, link, ...rest }: ComponentCardFragment) => {
  const icon = getFragmentData(assetFragment, iconFragment);
  const renderCard = (
    <div className={clsx("col-span-1 flex rounded-md bg-secondary-light-100/60 p-xs")}>
      <div
        className={clsx(
          "flex h-lg w-lg rounded-full bg-primary-light-100/20 p-2xs text-primary-light-500 dark:bg-primary-dark-500 dark:text-primary-light-50",
        )}
      >
        {icon && (
          <>
            {icon.url?.endsWith(".svg") ? (
              <Icon url={icon.url} strokeWidth={2} />
            ) : (
              <Image
                src={icon?.url || ""}
                alt={icon?.title || "Card Icon"}
                height="24"
                width="24"
                className="contrast-200 grayscale"
                quality={90}
                priority={false}
                loading="lazy"
              />
            )}
          </>
        )}
      </div>
      <div className="bg-white flex flex-1 items-center justify-between truncate rounded-r-md">
        <div className={clsx("flex-1 truncate px-4 py-2 text-sm")}>
          {heading && <span className="hover:  text-base font-medium leading-none">{heading}</span>}
          {body && <div className={clsx()}>{richTextParser(body as RichText)}</div>}
        </div>
      </div>
    </div>
  );

  return link ? (
    <Hyperlink href={link || "/"} className="hover:underline">
      {renderCard}
    </Hyperlink>
  ) : (
    renderCard
  );
};

export default PillCard;
