import { createClient } from "contentful-client";

const client = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID!,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_ACCESS_TOKEN!,
});

export default client;  



// CONTENTFUL TESTING REQUESTS

export function searchWebPages(query: string) {
  return client.getEntries({
    content_type: "templatePage",
    query,
    select: [
      "sys.id",
      "fields.slug",
      "fields.seo",
      "fields.internalName",
    ],
    include: 2
  });
}

export function searchBlogPosts(query: string) {
  return client.getEntries({
    content_type: "templateBlogPost",
    query,
    select: [
      "fields.title",
      "fields.slug",
      "fields.excerpt",
      "fields.category",
      "fields.publishDate",
      "fields.hideFromListings",
    ],
    include: 2,
  });
}

export function searchCaseStudies(query: string) {
  return client.getEntries({
    content_type: "templateCaseStudy",
    query,
    select: [
      "sys.id",
      "fields.title",
      "fields.slug",
      "fields.summary",
      "fields.body",
      "fields.seo",
      "fields.category",
    ],
    include: 2,
  });
}

export function searchIntegrations(query: string) {
  return client.getEntries({
    content_type: "templateIntegration",
    query,
    select: [
      "sys.id",
      "fields.title",
      "fields.slug",
      "fields.seo",
      "fields.category",
      "fields.externalLink"
    ],
    include: 2,
  });
}

export function searchEvents(query: string) {
  return client.getEntries({
    content_type: "templateEvent",
    query,
    select: [
      "sys.id",
      "fields.slug",
      "fields.eventDisplayName",
      "fields.metaSeo",
    ],
    include: 2,
  });
}

export function searchAll(query: string) {
  return Promise.all([
    searchWebPages(query),
    searchBlogPosts(query),
    searchCaseStudies(query),
    searchIntegrations(query),
    searchEvents(query),
  ]);
}

// DRUPAL TESTING REQUESTS

export function searchDrupalAll(query: string) {
  return fetch(`/api/drupal-search?query=${encodeURIComponent(query)}`)
    .then(response => response.json());
}
