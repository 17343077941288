import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import Image from "next/image";
import type { FC } from "react";
import { cn } from "utils/functions";

interface SpotlightCardProps {
  icon: AssetFragment | undefined;
  title: string;
  body?: string;
}

const SpotlightCard: FC<SpotlightCardProps> = ({ icon, title, body }) => {
  return (
    <div className="relative z-20 h-full overflow-hidden rounded-md bg-primary-neutral-50 p-6 text-left">
      <div className=" relative inline-flex items-center justify-center whitespace-nowrap rounded-full p-3">
        <span className="absolute left-0 top-0 h-full w-full rounded-full bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 "></span>
        <Image
          src={icon?.url || ""}
          alt={title}
          width={20}
          height={20}
          className="z-0 contrast-200 invert"
          quality={90}
          priority={false}
          loading="lazy"
        />
      </div>
      <div className="flex h-full flex-col pt-2 text-left">
        <div className="grow">
          <h2 className={cn(typography.heading.cardHeading, "pb-2")}>{title}</h2>
          <p className={cn(typography.body.md, "font-light text-secondary-dark-900/60")}>{body}</p>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 right-0 h-[150px] w-[150px]"
        style={{
          background: `radial-gradient(at 55% bottom, rgba(241,236,254,0.2) 40%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)`,
        }}
      ></div>
    </div>
  );
};

export default SpotlightCard;
