import React from "react";

export const SearchResultSkeleton = () => (
  <div className="group relative animate-pulse border-b border-primary-dark-100/60 px-4 py-5">
    {/* Title */}
    <div className="mb-1 h-7 w-3/4 rounded bg-primary-neutral-200" />

    {/* URL/Path */}
    <div className="mb-2 flex items-center gap-2">
      <div className="h-4 w-20 rounded bg-primary-neutral-200" />
      <div className="h-4 w-32 rounded bg-primary-neutral-200" />
    </div>

    {/* Tags/Date */}
    <div className="mb-4 flex items-center gap-2">
      <div className="h-5 w-16 rounded bg-primary-neutral-200" />
      <div className="h-5 w-24 rounded bg-primary-neutral-200" />
    </div>

    {/* Description */}
    <div className="space-y-2">
      <div className="h-4 w-full rounded bg-primary-neutral-200" />
      <div className="h-4 w-5/6 rounded bg-primary-neutral-200" />
    </div>
  </div>
);

export const SearchGridSkeleton = () => (
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
    {[...Array(8)].map((_, i) => (
      <SearchResultSkeleton key={i} />
    ))}
  </div>
);
