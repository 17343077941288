import { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import LinkedInIcon from "components/Icons/LinkedInIcon";
import TwitterIcon from "components/Icons/TwitterIcon";
import { ComponentCardFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment } from "contentful/fragments";

const TeamCardDeck = ({ teamMember }: ComponentCardFragment) => {
  const personImage = getFragmentData(assetFragment, teamMember?.headshot);
  return (
    <div>
      <Image
        className="aspect-[3/2] w-full rounded-lg object-cover shadow-lg"
        src={personImage?.url || ""}
        alt={personImage?.title || "Team member profile picture"}
        width={personImage?.width || 300}
        height={personImage?.height || 300}
        quality={90}
        priority={false}
        loading="lazy"
      />
      <div className="mt-sm">
        <div className="space-y-[5px]">
          <h3 className="text-xl font-medium leading-6">{teamMember?.name}</h3>
          <p className="text-xl font-normal leading-6">{teamMember?.role}</p>
        </div>
        {(teamMember?.twitter || teamMember?.linkedIn) && (
          <ul className="flex space-x-[5px] pt-2">
            {teamMember?.twitter && (
              <li>
                <Link href={teamMember.twitter} className=" hover:">
                  <span className="sr-only">Twitter</span>
                  <TwitterIcon />
                </Link>
              </li>
            )}
            {teamMember?.linkedIn && (
              <li>
                <Link href={teamMember?.linkedIn} className=" hover:">
                  <span className="sr-only">LinkedIn</span>
                  <LinkedInIcon />
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TeamCardDeck;
