import { cva, VariantProps } from "class-variance-authority";
import { clsx } from "clsx";
import CtaLink from "components/CtaLink";
import Icon from "components/Icon";
import typography from "components/typography";
import { assetFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentCardFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { Suspense } from "react";
import { cn } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";

const BasicCard = ({
  heading,
  headingType,
  callToAction: callToActionFragment,
  textAlign,
  layout,
  contained,
  body,
  image: imageFragment,
  icon: iconFragment,
  sys,
}: ComponentCardFragment) => {
  const callToAction = getFragmentData(ctaFragment, callToActionFragment);
  const icon = getFragmentData(assetFragment, iconFragment);
  const image = getFragmentData(assetFragment, imageFragment);

  const cardClasses = cva(["overflow-hidden flex flex-col-reverse md:flex-row"], {
    variants: {
      contained: {
        true: "rounded-2xl bg-secondary-light-100/60 dark:bg-primary-dark-700/30 dark:border dark:border-primary-dark-500/30",
      },
      textAlign: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
    },
  });

  return (
    <div
      key={sys.id}
      className={cardClasses({
        contained: contained,
        textAlign: textAlign?.toLowerCase() as VariantProps<typeof cardClasses>["textAlign"],
      })}
    >
      <div className={cn("flex flex-col justify-center", contained ? "p-xs lg:p-sm 2xl:p-md" : "")}>
        {icon?.url?.endsWith(".svg") && (
          <div
            className={
              "mb-2xs flex h-lg w-lg rounded-full bg-primary-light-100/20 p-2xs text-primary-light-500 dark:bg-primary-dark-700 dark:text-primary-light-50 xl:mb-xs"
            }
          >
            <Icon url={icon.url} />
          </div>
        )}
        <div>
          {heading && headingType === "H2" ? (
            <h2
              className={clsx(
                typography.heading.componentHeading,
                "mb-3xs text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h2>
          ) : headingType === "H3" ? (
            <h3
              className={clsx(
                typography.heading.secondaryComponentHeading,
                "mb-3xs text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h3>
          ) : headingType === "H4" ? (
            <h4
              className={clsx(
                typography.heading.cardHeading,
                "mb-3xs text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h4>
          ) : (
            <h3
              className={clsx(
                typography.heading.cardHeading,
                "mb-3xs text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h3>
          )}

          <Suspense fallback={""}>
            {body && (
              <dd className={cn(typography.body.cardBody, "text-primary-dark-700 dark:text-primary-neutral-200")}>
                {body &&
                  richTextParser(body as RichText, headingType === "H4" ? "xs" : headingType === "H3" ? "sm" : "md")}
              </dd>
            )}
          </Suspense>
          {callToAction && (
            <div className="mt-sm">
              <CtaLink {...callToAction} />
            </div>
          )}
        </div>
      </div>
      {image?.url && (
        <div className="w-full flex-shrink-0 md:w-1/2">
          <Image
            src={image?.url || ""}
            alt={image?.title || "Image"}
            width={image?.width || 832}
            height={image?.height || 520}
            sizes="50vw"
            quality={90}
            priority={false}
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
};

export default BasicCard;
