"use client";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import Hyperlink from "components/Hyperlink";
import Icon from "components/Icon";
import { assetFragment, menuItemFragment } from "contentful/fragments";
import { FragmentType, getFragmentData } from "contentful/gql";
import GithubLogo_16px from "icons/GithubLogo_16px";
import { cn } from "utils/functions";
import styles from "./Navbar.module.css";
import Link from "next/link";
import Image from "next/image";

export default function Navbar({
  githubStars,
  menuItems,
}: {
  githubStars: number;
  menuItems: (FragmentType<typeof menuItemFragment> | null)[] | undefined;
}) {
  return (
    <NavigationMenu.Root
      className={clsx("relative col-span-6 hidden font-body text-base font-medium leading-none lg:block")}
      delayDuration={50}
    >
      <NavigationMenu.List className="flex items-center justify-center space-x-sm text-sm lg:text-base xl:space-x-md">
        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Hyperlink
              href="https://www.github.com/appsmithorg/appsmith"
              className={clsx(
                "flex items-center gap-4xs text-sm text-primary-light-500 transition-colors hover:text-primary-light-300",
              )}
              aria-label={"Appsmith Github Repo"}
            >
              <GithubLogo_16px height="16px" width="16px" title="Github Logo" />
              {githubStars} <span className="sr-only">Stars</span>
            </Hyperlink>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        {menuItems?.map((itemFragment) => {
          const item = getFragmentData(menuItemFragment, itemFragment);
          if (item?.menuCollection?.items && item.menuCollection.items.length > 0) {
            return (
              <NavigationMenu.Item key={item.sys.id}>
                <NavigationMenu.Trigger className="group/dropdown flex items-end text-base">
                  {item?.heading}
                  <span className="ml-4xs h-4 w-4 flex-shrink-0 transition-transform duration-300 group-data-[state='open']/dropdown:-rotate-180">
                    <Icon url="/assets/icons/arrow-sm-down-glyph-16.svg" />
                  </span>
                </NavigationMenu.Trigger>
                <NavigationMenu.Content
                  className={cn(
                    styles.NavigationMenuContent,
                    "absolute rounded border border-primary-dark-100/50 bg-primary-neutral-50 shadow-xl",
                  )}
                >
                  <div className="flex">
                    <div>
                      <div className="flex items-center">
                        <p className="mb-xs pl-md pt-sm text-md font-bold text-primary-dark-900">{item?.heading}</p>
                        <Image
                          src="/assets/icons/arrow-right-glyph-16.svg"
                          alt="arrow"
                          width={13}
                          height={13}
                          className="ml-2 pt-2 text-primary-dark-900"
                        />
                      </div>
                      <div className="flex">
                        <div className="w-full min-w-[220px] pl-md pr-md pt-xs">
                          {item?.menuTitle && (
                            <p className="mb-xs text-xs font-medium text-primary-dark-500">{item?.menuTitle}</p>
                          )}
                          <ul className="mb-xs">
                            {item?.menuCollection?.items?.map((item) => {
                              const icon = getFragmentData(assetFragment, item?.icon);
                              return (
                                item && (
                                  <Hyperlink
                                    key={item.sys.id}
                                    href={item.link ?? ""}
                                    className="group flex items-center py-3xs"
                                  >
                                    <div className={"relative mr-xs h-8 w-8 p-2"}>
                                      <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 transition-opacity duration-300 group-hover:opacity-0"></span>
                                      <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500 to-primary-light-650 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
                                      <span className="relative z-10 block h-full w-full text-primary-light-500 transition-colors duration-300 group-hover:text-primary-neutral-50">
                                        <Icon strokeWidth={1} url={icon?.url ?? ""} />
                                      </span>
                                    </div>
                                    <span className="min-w-min whitespace-nowrap text-sm font-medium text-primary-dark-500 transition-colors group-hover:text-primary-dark-900">
                                      {item?.heading}
                                    </span>
                                  </Hyperlink>
                                )
                              );
                            })}
                          </ul>
                          <div>
                            {!!item.link && (
                              <Hyperlink
                                href={item.link}
                                className="flex items-center pl-lg text-xs font-normal text-primary-dark-500"
                              >
                                See all{" "}
                                <Image
                                  src="/assets/icons/arrow-right-glyph-16.svg"
                                  alt="arrow"
                                  width={10}
                                  height={10}
                                  className="ml-1 pt-1 text-primary-dark-900"
                                />
                              </Hyperlink>
                            )}
                          </div>
                        </div>
                        {item?.menuIiCollection?.items && item.menuIiCollection.items.length > 0 && (
                          <div className="w-full min-w-[250px] pr-md pt-xs">
                            {item?.menuIiTitle && (
                              <p className="mb-xs text-xs font-medium text-primary-dark-500">{item?.menuIiTitle}</p>
                            )}
                            <ul className="mb-xs">
                              {item?.menuIiCollection?.items?.map((item) => {
                                const icon = getFragmentData(assetFragment, item?.icon);
                                return (
                                  item && (
                                    <Hyperlink
                                      key={item.sys.id}
                                      href={item.link ?? ""}
                                      className="group flex items-center py-3xs"
                                    >
                                      <div className={"relative mr-xs h-8 w-8 p-2"}>
                                        <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 transition-opacity duration-300 group-hover:opacity-0"></span>
                                        <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500 to-primary-light-650 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
                                        <span className="relative z-10 block h-full w-full text-primary-light-500 transition-colors duration-300 group-hover:text-primary-neutral-50">
                                          <Icon strokeWidth={1} url={icon?.url ?? ""} />
                                        </span>
                                      </div>
                                      <span className="text-sm font-medium text-primary-dark-500 transition-colors group-hover:text-primary-dark-900">
                                        {item?.heading}
                                      </span>
                                    </Hyperlink>
                                  )
                                );
                              })}
                            </ul>
                            <div>
                              {!!item.link && (
                                <Hyperlink
                                  href={item.link}
                                  className="flex items-center pl-lg text-xs font-normal text-primary-dark-500"
                                >
                                  See all{" "}
                                  <Image
                                    src="/assets/icons/arrow-right-glyph-16.svg"
                                    alt="arrow"
                                    width={10}
                                    height={10}
                                    className="ml-1 pt-1 text-primary-dark-900"
                                  />
                                </Hyperlink>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {item?.featuredContent && (
                      <div className="min-w-[230px] bg-primary-light-500/5 px-md py-sm">
                        <div className="mb-md flex items-center">
                          <p className="text-sm font-bold text-primary-dark-900">{item?.featuredContent?.heading}</p>
                          <Image
                            src="/assets/icons/arrow-right-glyph-16.svg"
                            alt="arrow"
                            width={13}
                            height={13}
                            className="ml-2 text-primary-dark-900"
                          />
                        </div>
                        {item?.featuredContent?.items?.map(
                          (featuredItem: {
                            imageAssetReferenceId?: string;
                            sys?: { id: string };
                            url?: string;
                            title?: string;
                          }) => {
                            const image = featuredItem.imageAssetReferenceId
                              ? item.featuredImageCollection?.items?.find(
                                  (img: any) => img?.sys?.id === featuredItem.imageAssetReferenceId,
                                )
                              : null;

                            const processedImage = image ? getFragmentData(assetFragment, image) : null;

                            return (
                              <div className="mb-sm" key={featuredItem?.sys?.id || featuredItem?.url}>
                                <Link href={featuredItem?.url ?? ""}>
                                  {processedImage && (
                                    <Image
                                      src={processedImage.url ?? ""}
                                      alt={featuredItem?.title ?? ""}
                                      width={166}
                                      height={96}
                                      className="mb-4xs h-24 w-full rounded-lg border-4 border-primary-light-50  object-cover"
                                    />
                                  )}

                                  <span className="line-clamp-2 pl-2 text-xs font-normal text-primary-dark-500">
                                    {featuredItem?.title}
                                  </span>
                                </Link>
                              </div>
                            );
                          },
                        )}
                        <Link
                          key={item.sys.id}
                          href={item?.featuredContent?.bottomLink?.url ?? ""}
                          className="text-xs font-normal text-primary-light-500"
                        >
                          {item?.featuredContent?.bottomLink?.label} ↗
                        </Link>
                      </div>
                    )}
                  </div>
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            );
          } else {
            return (
              <NavigationMenu.Item key={item?.sys.id} asChild>
                <Hyperlink className="colors transition hover:text-primary-dark-500" href={item?.link ?? ""}>
                  {item?.heading}
                </Hyperlink>
              </NavigationMenu.Item>
            );
          }
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
