import Hyperlink from "components/Hyperlink";
import { assetFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { TemplateWidgetListingFragment } from "contentful/gql/graphql";
import Image from "next/image";

export default function WidgetIconCard({ widget }: { widget: TemplateWidgetListingFragment }) {
  const { title, externalLink, slug, widgetIcon: image } = widget;
  const widgetIcon = getFragmentData(assetFragment, image);
  return (
    <Hyperlink
      href={externalLink ?? `https://docs.appsmith.com/reference/widgets/${slug}`}
      className="group flex flex-col items-center justify-center rounded-lg border border-primary-light-50 bg-primary-neutral-50 px-sm py-xs dark:border-primary-dark-800 dark:bg-primary-dark-700"
    >
      {widgetIcon && widgetIcon.url ? (
        <Image
          className="mx-auto mb-2xs h-md w-auto object-contain"
          src={widgetIcon?.url || ""}
          alt={widgetIcon?.title || "Widget icon"}
          width={widgetIcon?.width || 32}
          height={widgetIcon?.height || 32}
          quality={90}
          priority={false}
          loading="lazy"
        />
      ) : null}
      <h3 className="whitespace-nowrap text-center font-display text-xs font-bold leading-extra-tight group-hover:underline">
        {title}
      </h3>
    </Hyperlink>
  );
}
