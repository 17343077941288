"use client";

import Icon from "components/Icon";
import typography from "components/typography";
import { analytics } from "lib/segment";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { segmentTrackBooking } from "utils/analytics";
import { cn } from "utils/functions";

const LowCodeContactForm: FC = () => {
  const [country, setCountry] = useState<string>("");
  const [countryRegion, setCountryRegion] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  function isValidEmail(email: string) {
    return !(
      email.includes("@gmail.com") ||
      email.includes("@icloud.com") ||
      email.includes("@mailinator.com") ||
      email.includes("@business.com") ||
      email.includes("@edu.com") ||
      email.includes("@teleworm.com")
    );
  }

  const validEmail = isValidEmail(email);

  const searchParams = useSearchParams();

  const formDataRef = useRef({
    form: "Low Code Contact Form",
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    lead_source: searchParams.get("lead_source"),
    role: "",
    company_size: "",
    message: "",
    questions: "",
    country: country,
    company_name: companyName,
    countryRegion: countryRegion,
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
  });

  useEffect(() => {
    fetch("/api/get-geo-and-ip")
      .then((response) => response.json())
      .then((data: any) => {
        setCountry(data.geo.country?.toUpperCase());
        setCountryRegion(data.geo.region?.toUpperCase());
      });
  }, []);

  function trackBookingActionsHandler(event: MessageEvent) {
    segmentTrackBooking(event.data["action"], formDataRef.current);
  }

  useEffect(() => {
    window.addEventListener("message", trackBookingActionsHandler, false);

    return () => {
      window.removeEventListener("message", trackBookingActionsHandler, false);
    };
  }, []);

  return (
    <div className="rounded-lg p-sm xl:p-md">
      <Script id="script-chilipiper-src" src="https://js.chilipiper.com/marketing.js" />
      <Script id="script-chilipiper-action">
        {`function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments])}}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a},{});ChiliPiper.scheduling("appsmith", "inbound_router", {title: "Thanks! What time works best for a quick call?"})`}
      </Script>

      <h2 className={cn(typography.heading.sectionHeading, "text-center text-tertiary-pink-500")}>
        Find out how you can build, deploy, and maintain custom software more quickly and effectively with Appsmith
      </h2>
      <form
        id="wf-form-Stay-updated-on-the-launch-Form"
        name="wf-form-Stay-updated-on-the-launch-Form"
        data-name="Stay updated on the launch Form"
        action="#"
        method="get"
        aria-label="Stay updated on the launch Form"
        className="mt-md flex flex-col items-center gap-[20px] text-base"
        onSubmit={(e) => {
          formDataRef.current = {
            ...formDataRef.current,
          };
          analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
          analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });
        }}
      >
        <div className="grid grid-cols-1 gap-[20px] text-base sm:grid-cols-2">
          <div className="grid grid-cols-2 gap-2xs">
            <div>
              <div className="mt-4xs">
                <input
                  type="text"
                  maxLength={256}
                  name="Firstname"
                  id="Firstname"
                  value={firstName}
                  onChange={(e) => {
                    formDataRef.current.first_name = e.target.value;
                    setFirstName(e.target.value);
                  }}
                  required
                  autoComplete="given-name"
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                  placeholder="First name"
                />
              </div>
            </div>
            <div>
              <div className="mt-4xs">
                <input
                  type="text"
                  name="Lastname"
                  id="Lastname"
                  value={lastName}
                  onChange={(e) => {
                    formDataRef.current.last_name = e.target.value;
                    setLastName(e.target.value);
                  }}
                  required
                  autoComplete="family-name"
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                  placeholder="Last name"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="mt-4xs">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    formDataRef.current.work_email = e.target.value;
                    setEmail(e.target.value);
                  }}
                  onBlur={() => {
                    if (validEmail) {
                      analytics?.identify(email, { email: email });
                    }
                  }}
                  required
                  autoComplete="email"
                  className={cn(
                    "block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]",
                    !validEmail ? "border-tertiary-red-500" : "",
                  )}
                  placeholder="Email"
                />
                {!validEmail ? (
                  <p className="mt-[5px] text-sm text-tertiary-red-500">Please enter a valid work email address.</p>
                ) : null}
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="mt-4xs">
                <input
                  id="companyName"
                  name="companyName"
                  type="companyName"
                  value={companyName}
                  onChange={(e) => {
                    formDataRef.current.company_name = e.target.value;
                    setCompanyName(e.target.value);
                  }}
                  required
                  autoComplete="company-name"
                  className={cn(
                    "block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]",
                    !validEmail ? "border-tertiary-red-500" : "",
                  )}
                  placeholder="Company Name"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-1">
            <div className="mt-4xs">
              <textarea
                id="Message"
                name="Message"
                aria-describedby="Message"
                rows={5}
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                value={message}
                onChange={(e) => {
                  formDataRef.current.message = e.target.value;
                  setMessage(e.target.value);
                }}
                placeholder="Your Message"
              />
            </div>
          </div>
        </div>

        <div className="">
          <button
            type="submit"
            disabled={!validEmail}
            className={cn(
              "group flex items-center justify-center whitespace-nowrap rounded px-sm py-xs font-medium leading-none",
              "bg-gradient-to-b from-tertiary-pink-500 to-tertiary-pink-500 text-primary-neutral-50 transition-colors ",
              !validEmail
                ? "cursor-not-allowed opacity-80"
                : "hover:from-tertiary-pink-500/20 hover:to-tertiary-pink-500/20 hover:text-tertiary-pink-500 dark:hover:text-primary-neutral-50",
            )}
          >
            Book a meeting
            <div className="ml-2xs h-4 w-4">
              <Icon url="/assets/icons/arrow-right-glyph-16.svg" />
            </div>
          </button>
          <input
            type="hidden"
            name="lead_source"
            id="lead_source"
            value={formDataRef.current.lead_source ?? "Website"}
          />
          <input type="hidden" name="country" value={country} />
          <input type="hidden" name="countryRegion" value={countryRegion} />
        </div>
      </form>
      <div className="success-message w-form-done hidden">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="w-form-fail hidden">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  );
};

export default LowCodeContactForm;
