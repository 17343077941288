"use client";

import { FC, useState } from "react";
import Image from "next/image";
import PlayIcon from "components/Icons/PlayIcon";
import { getYoutubeId, parseVimeo } from "utils/parseUrl";
import { slug } from "github-slugger";
import Script from "next/script";
import { ComponentVideoFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment } from "contentful/fragments";

const VideoComponent = ({
  url,
  thumbnail: thumbnailFragment,
  title,
  mediaCollection,
}: Omit<ComponentVideoFragment, "sys" | "__typename">) => {
  const thumbnail = getFragmentData(assetFragment, thumbnailFragment);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  if (mediaCollection?.items?.length) {
    const asset = getFragmentData(assetFragment, mediaCollection.items[0]);
    return (
      <video
        src={asset?.url + "?fm=mp4"}
        className="mx-auto my-sm h-auto w-full max-w-screen-lg overflow-hidden rounded-lg"
        width="100%"
        height="auto"
        muted
        autoPlay
        loop
        playsInline
      >
        <source src={asset?.url + "?fm=mp4"} type="video/mp4" />
      </video>
    );
  } else {
    const youtubeID = getYoutubeId(url || "");
    const videoUrl = youtubeID
      ? `https://www.youtube.com/embed/${youtubeID}?enablejsapi=1&autoplay=1`
      : parseVimeo(url || "");

    return (
      <figure className="my-xs aspect-video h-auto w-full overflow-hidden rounded-xl">
        {!youtubeID && <Script id="script-vimeoPlayer-src" src="https://player.vimeo.com/api/player.js" />}
        {isVideoPlay ? (
          <>
            <iframe
              id={"video-iframe-" + slug(title || "")}
              src={videoUrl || ""}
              title=""
              width="1280"
              height="720"
              allowFullScreen
              allow="autoplay; accelerometer; clipboard-write;encrypted-media; gyroscope; picture-in-picture;"
              loading="lazy"
              className="h-full w-full"
            />
            {youtubeID ? (
              <Script id="script-youtubePlayer-analytics">
                {`
                    var tag = document.createElement('script');
                    tag.src = "https://www.youtube.com/iframe_api";
                    var firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                    var player;

                    onYouTubeIframeAPIReady = function() {
                      player = new YT.Player('video-iframe-${slug(title || "")}', {
                        events: {
                          'onStateChange': onPlayerStateChange
                        }
                      });
                    }
                    if (analytics) {
                      analytics?.ready(function () {
                        function onPlayerStateChange(event) {
                          if (event.data == YT.PlayerState.PLAYING) {
                            analytics?.track('Video Played', {
                              videoTitle: '${title}',
                              videoUrl: '${url}',
                              percent: event.target.getDuration(),
                              seconds: event.target.getCurrentTime()
                            });
                          }
                          if (event.data == YT.PlayerState.PAUSED) {
                            analytics?.track('Video Paused', {
                              videoTitle: '${title}',
                              videoUrl: '${url}',
                              percent: event.target.getDuration(),
                              seconds: event.target.getCurrentTime()
                            });
                          }
                          if (event.data == YT.PlayerState.SEEKED) {
                            analytics?.track('Video Seeked', {
                              videoTitle: '${title}',
                              videoUrl: '${url}',
                              percent: event.target.getDuration(),
                              seconds: event.target.getCurrentTime()
                            });
                          }
                          if (event.data == YT.PlayerState.ENDED) {
                            analytics?.track('Video Completed', {
                              videoTitle: '${title}',
                              videoUrl: '${url}',
                            });
                          }
                        }
                      })
                    }
                  `}
              </Script>
            ) : (
              <Script id={"script-vimeoPlayer-analytics-" + slug(title || "")}>
                {`
                analytics?.ready(function () {
                  let vimeoPlayer = new Vimeo.Player(document.querySelector('#video-iframe-${slug(title || "")}'));
                  vimeoPlayer.on('play', function ({duration, percent, seconds}) {
                    analytics?.track('Video Played', {
                      videoTitle: '${title}',
                      videoUrl: '${url}',
                      percent: percent,
                      seconds: seconds
                    });
                  });
                  vimeoPlayer.on('pause', function ({duration, percent, seconds}) {
                    analytics?.track('Video Paused', {
                      videoTitle: '${title}',
                      videoUrl: '${url}',
                      percent: percent,
                      seconds: seconds
                    });
                  });
                  vimeoPlayer.on('seeked', function ({duration, percent, seconds}) {
                    analytics?.track('Video Seeked', {
                      videoTitle: '${title}',
                      videoUrl: '${url}',
                      percent: percent,
                      seconds: seconds
                    });
                  });
                  vimeoPlayer.on('ended', function () {
                    analytics?.track('Video Completed', {
                      videoTitle: '${title}',
                      videoUrl: '${url}',
                    });
                  });
                })`}
              </Script>
            )}
          </>
        ) : (
          <div className="relative h-full w-full cursor-pointer" onClick={() => setIsVideoPlay(true)}>
            {thumbnail ? (
              <Image
                src={thumbnail?.url || ""}
                alt={thumbnail?.title || "Video Thumbnail"}
                width={thumbnail?.width || "1280"}
                height={thumbnail?.height || "720"}
                className="h-full w-full object-cover"
                quality={90}
                priority={false}
                loading="lazy"
              />
            ) : (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={`https://img.youtube.com/vi/${youtubeID}/maxresdefault.jpg`}
                className="h-full w-full object-cover"
                alt={title ?? "Thumbnail Image"}
              />
            )}
            <span className="bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full shadow-lg">
              <PlayIcon fill="#ff6c2c" size="100px" />
            </span>
          </div>
        )}
      </figure>
    );
  }
};

export default VideoComponent;
