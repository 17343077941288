export function isValidEmail(email: string) {
    const genericDomains = new Set([
        "@gmail.com", "@icloud.com", "@mailinator.com", "@business.com", "@edu.com", "@teleworm.com",
        "@yahoo.com", "@hotmail.com", "@aol.com", "@outlook.com", "@live.com", "@msn.com",
        "@comcast.net", "@verizon.net", "@att.net", "@sbcglobal.net", "@bellsouth.net", "@charter.net",
        "@cox.net", "@earthlink.net", "@juno.com", "@mindspring.com", "@netzero.net", "@roadrunner.com",
        "@prodigy.net", "@q.com", "@rocketmail.com", "@ymail.com", "@mail.com", "@inbox.com",
        "@gmx.com", "@hushmail.com", "@fastmail.com", "@zoho.com", "@yandex.com", "@protonmail.com", "@yahoo.com.br",
        
        "@me.com", "@mac.com", "@googlemail.com", "@yahoo.co.uk", "@yahoo.fr", "@yahoo.de", 
        "@yahoo.it", "@yahoo.es", "@yahoo.ca", "@yahoo.in", "@yahoo.com.au", "@yahoo.co.jp",
        "@outlook.fr", "@outlook.de", "@outlook.it", "@outlook.es", "@outlook.jp", "@outlook.in",
        "@hotmail.co.uk", "@hotmail.fr", "@hotmail.de", "@hotmail.it", "@hotmail.es", "@hotmail.com.br",
        "@gmx.de", "@gmx.net", "@gmx.at", "@gmx.ch", "@web.de", "@t-online.de",
        "@mail.ru", "@rambler.ru", "@yahoo.co.in", "@rediffmail.com", "@libero.it",
        "@btinternet.com", "@virginmedia.com", "@blueyonder.co.uk", "@freeserve.co.uk", "@ntlworld.com",
        "@wanadoo.fr", "@orange.fr", "@free.fr", "@sfr.fr", "@laposte.net",
        "@qq.com", "@163.com", "@126.com", "@yeah.net", "@sina.com", "@sohu.com",
        "@tutanota.com", "@tutanota.de", "@tutamail.com", "@tuta.io",
        "@pm.me", "@proton.me", "@hey.com", "@duck.com"
    ]);

    const emailDomain = email.substring(email.indexOf('@')).toLowerCase();

    return !genericDomains.has(emailDomain);
}