import React, { useState, useCallback, useRef } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { debounce } from "lodash";
import { searchBlogPosts, searchAll, searchDrupalAll } from "../../contentful/search";
import Link from "next/link";
import Image from "next/image";
import { SearchGridSkeleton } from "./SearchSkeleton";

interface SearchResult {
  id: string;
  contentType: string;
  fields: {
    title: string;
    excerpt?: string;
    slug: string;
    externalLink?: string;
    summary?: string;
    hideFromListings?: boolean;
    seo?: {
      fields: {
        pageTitle: string;
        pageDescription: string;
      };
      sys: {
        createdAt: string;
      };
    };
    metaSeo?: {
      fields: {
        pageTitle: string;
        pageDescription: string;
      };
      sys: {
        createdAt: string;
      };
    };
    category?: string;
    publishDate?: string;
  };
}

// Helper function to highlight search terms
const highlightText = (text: string | undefined, searchTerm: string) => {
  if (!text || !searchTerm) return text || "";

  const regex = new RegExp(`(${searchTerm})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, i) =>
    regex.test(part) ? (
      <strong className="text-primary-light-700" key={i}>
        {part}
      </strong>
    ) : (
      part
    ),
  );
};

// Add this helper function at the top of the file, near the highlightText function
const stripHtmlTags = (html: string | undefined) => {
  if (!html) return "";
  // First remove HTML tags
  const withoutTags = html.replace(/<[^>]*>/g, "");
  // Then decode HTML entities and normalize whitespace
  return withoutTags
    .replace(/&nbsp;/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/\s+/g, " ")
    .trim();
};

// Move getResultUrl outside of components, near the top helper functions
const getResultUrl = (result: SearchResult) => {
  if (!result.fields.slug && !result.fields.externalLink) return "#"; // Fallback URL

  const prefix =
    result.contentType === "blog"
      ? "/blog"
      : result.contentType === "case-study"
        ? "/case-studies"
        : result.contentType === "integration"
          ? "/integration"
          : result.contentType === "event"
            ? "/event"
            : "";

  // For integrations, use externalLink if available
  if (result.contentType === "integration" && result.fields.externalLink) {
    return result.fields.externalLink;
  }

  // Otherwise use the slug with prefix
  return `${prefix}${result.fields.slug.startsWith("/") ? result.fields.slug : `/${result.fields.slug}`}`;
};

// Add prop type for the component
interface SearchModalProps {
  searchType?: "all" | "blog";
}

export const SearchModal: React.FC<SearchModalProps> = ({ searchType = "all" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [drupalResults, setDrupalResults] = useState<{
    solutions: any[];
    templates: any[];
    videos: any[];
  }>({
    solutions: [],
    templates: [],
    videos: [],
  });

  // Group results by content type
  const groupedResults = {
    pages: searchResults.filter((result) => result.contentType === "page"),
    blog: searchResults.filter((result) => result.contentType === "blog"),
    cases: searchResults.filter((result) => result.contentType === "case-study"),
    integrations: searchResults.filter((result) => result.contentType === "integration"),
    events: searchResults.filter((result) => result.contentType === "event"),
  };
  // Modify debouncedSearch to handle different search types
  const debouncedSearch = useCallback(
    debounce(async (term: string) => {
      setSearchTerm(term);
      if (term.trim().length >= 3) {
        setIsLoading(true);
        try {
          // Test Drupal videos search separately
          const drupalVideos = await searchDrupalAll(term);

          if (searchType === "blog") {
            // Search only blog posts
            const posts = await searchBlogPosts(term);
            const blogResults = posts.items
              .filter((item) => !item.fields.hideFromListings)
              .map((item) => ({
                id: item.sys.id,
                contentType: "blog",
                fields: item.fields,
              }));
            setSearchResults(blogResults as SearchResult[]);
          } else {
            // Search all content types
            const [pages, posts, cases, integrations, events] = await searchAll(term);

            const allResults = [
              ...pages.items.map((item) => ({ id: item.sys.id, contentType: "page", fields: item.fields })),
              ...posts.items
                .filter((item) => !item.fields.hideFromListings)
                .map((item) => ({ id: item.sys.id, contentType: "blog", fields: item.fields })),
              ...cases.items.map((item) => ({ id: item.sys.id, contentType: "case-study", fields: item.fields })),
              ...integrations.items.map((item) => ({
                id: item.sys.id,
                contentType: "integration",
                fields: item.fields,
              })),
              ...events.items.map((item) => ({ id: item.sys.id, contentType: "event", fields: item.fields })),
            ];
            setSearchResults(allResults as SearchResult[]);
          }
        } catch (error) {
          console.error("Search failed:", error);
          setSearchResults([]);
        }
        setIsLoading(false);
      } else {
        setSearchResults([]);
      }
    }, 500),
    [searchType],
  );

  // Handle input changes
  const handleSearchInput = useCallback(
    debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchTerm(value);

      // Clear results immediately
      setSearchResults([]);
      setDrupalResults({ solutions: [], templates: [], videos: [] });

      // Set loading state if search term is valid
      if (value.length >= 3) {
        setIsLoading(true); // Set loading before the API calls

        try {
          const [contentfulResults, drupalSearchResults] = await Promise.all([
            searchAll(value),
            searchDrupalAll(value),
          ]);

          // Transform contentful results
          const allResults = [
            ...contentfulResults[0].items.map((item) => ({
              id: item.sys.id,
              contentType: "page",
              fields: item.fields,
            })),
            ...contentfulResults[1].items
              .filter((item) => !item.fields.hideFromListings)
              .map((item) => ({
                id: item.sys.id,
                contentType: "blog",
                fields: item.fields,
              })),
            ...contentfulResults[2].items.map((item) => ({
              id: item.sys.id,
              contentType: "case-study",
              fields: item.fields,
            })),
            ...contentfulResults[3].items.map((item) => ({
              id: item.sys.id,
              contentType: "integration",
              fields: item.fields,
            })),
            ...contentfulResults[4].items.map((item) => ({
              id: item.sys.id,
              contentType: "event",
              fields: item.fields,
            })),
          ];

          setSearchResults(allResults as SearchResult[]);
          setDrupalResults(drupalSearchResults);
        } catch (error) {
          console.error("Search error:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }, 300),
    [],
  );

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="hover:bg-gray-100 mb-xs flex w-full max-w-[286px] items-center justify-center gap-2 rounded-2xl border border-primary-dark-100 bg-primary-neutral-50 px-6 py-1 text-sm font-medium text-primary-dark-500 transition-all duration-300 hover:border-primary-light-500 hover:shadow-sm"
        aria-label="Search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>{" "}
        Search
      </button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50" initialFocus={inputRef}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-black/30 fixed inset-0 shadow-sm backdrop-blur-lg" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={`relative mx-auto w-full max-w-7xl overflow-y-auto rounded-lg bg-secondary-light-100/95 p-6 shadow-2xl backdrop-blur-sm ${
                searchTerm.length >= 3 ? "h-[90vh]" : "max-h-[90vh]"
              }`}
            >
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700 absolute right-2 top-2 z-50 rounded-full bg-secondary-light-50/95 p-1.5"
                aria-label="Close search"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
              <div className={`${searchTerm.length >= 3 ? "h-full" : "min-h-[600px]"}`}>
                <div className="relative">
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Type to search (min. 3 chars)"
                    onChange={handleSearchInput}
                    className="focus:ring-blue-500 mt-5 w-full rounded-lg border p-3 focus:outline-none focus:ring-2"
                  />
                </div>

                {searchType === "all" ? (
                  <Tab.Group>
                    <Tab.List className="mt-3 flex flex-wrap border-primary-dark-200 lg:border-b">
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        All{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          (
                          {searchResults.length +
                            (drupalResults.videos?.length || 0) +
                            (drupalResults.solutions?.length || 0) +
                            (drupalResults.templates?.length || 0)}
                          )
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Website{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({groupedResults.pages.length})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Blog{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({groupedResults.blog.length})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Case Studies{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({groupedResults.cases.length})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Integrations{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({groupedResults.integrations.length})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Events{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({groupedResults.events.length})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Videos{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({drupalResults.videos?.length || 0})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                      >
                        Community{" "}
                        <span className="ml-1 align-sub text-xs text-primary-dark-500">
                          ({(drupalResults.solutions?.length || 0) + (drupalResults.templates?.length || 0)})
                        </span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          `border-r border-primary-dark-200 px-4 py-1 text-sm font-medium ${
                            selected
                              ? "border-b-2 border-primary-light-700 text-primary-light-700"
                              : "text-gray-500 hover:text-gray-700"
                          }`
                        }
                        onClick={() => {
                          window.open(`https://docs.appsmith.com/search?q=${encodeURIComponent(searchTerm)}`, "_blank");
                        }}
                      >
                        <div className="flex items-center gap-1">
                          Docs
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="ml-1"
                          >
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                            <polyline points="15 3 21 3 21 9" />
                            <line x1="10" y1="14" x2="21" y2="3" />
                          </svg>
                        </div>
                      </Tab>
                    </Tab.List>

                    <Tab.Panels className="mt-10 pb-10">
                      <div className="min-h-[400px]">
                        {isLoading ? (
                          <SearchGridSkeleton />
                        ) : searchTerm.length < 3 ? (
                          <div className="flex h-[400px] items-center justify-center">
                            <p className="text-gray-600 text-sm">Please enter at least 3 characters to search.</p>
                          </div>
                        ) : (
                          <>
                            <Tab.Panel>
                              {/* Website Pages Section */}
                              {groupedResults.pages.length > 0 && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Website Pages</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {groupedResults.pages.length} results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {groupedResults.pages.slice(0, 8).map((result) => (
                                      <Link
                                        key={result.id}
                                        href={getResultUrl(result)}
                                        target="_blank"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(
                                              result.fields.seo?.fields?.pageTitle ||
                                                result.fields.metaSeo?.fields?.pageTitle ||
                                                result.fields.title,
                                              searchTerm,
                                            )}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <span className="line-clamp-1 text-xs text-primary-dark-500">
                                              <span className="font-bold capitalize">
                                                {result.contentType.replace("-", " ")}
                                              </span>
                                              <span> {" • "}</span>
                                              {result.fields.slug && (
                                                <span>
                                                  {result.fields.slug
                                                    .split("/")
                                                    .filter(Boolean)
                                                    .map((part, index, arr) => (
                                                      <span key={index}>
                                                        <span className="capitalize">
                                                          {highlightText(part.replace(/-/g, " "), searchTerm)}
                                                        </span>
                                                        {index < arr.length - 1 && <span className="mx-1">/</span>}
                                                      </span>
                                                    ))}
                                                </span>
                                              )}
                                            </span>
                                          </p>
                                          <div className="mb-4 flex min-h-[25px] items-center gap-2">
                                            {result.fields.category && (
                                              <span className="rounded-md bg-secondary-light-50 px-1 py-0.5 text-xs capitalize text-primary-dark-500">
                                                {result.fields.category}
                                              </span>
                                            )}
                                            {(() => {
                                              return (
                                                (result.fields.publishDate ||
                                                  result.fields.seo?.sys?.createdAt ||
                                                  result.fields.metaSeo?.sys?.createdAt) && (
                                                  <time
                                                    className="text-xs text-primary-dark-500"
                                                    dateTime={
                                                      result.fields.publishDate ||
                                                      result.fields.seo?.sys?.createdAt ||
                                                      result.fields.metaSeo?.sys?.createdAt
                                                    }
                                                  >
                                                    {new Date(
                                                      result.fields.publishDate ||
                                                        result.fields.seo?.sys?.createdAt ||
                                                        result.fields.metaSeo?.sys?.createdAt ||
                                                        new Date().toISOString(),
                                                    ).toLocaleDateString()}
                                                  </time>
                                                )
                                              );
                                            })()}
                                          </div>

                                          {(result.fields.seo?.fields?.pageDescription ||
                                            result.fields.metaSeo?.fields?.pageDescription ||
                                            result.fields.excerpt) && (
                                            <p className="text-gray-600 line-clamp-3">
                                              {highlightText(
                                                result.fields.seo?.fields?.pageDescription ||
                                                  result.fields.metaSeo?.fields?.pageDescription ||
                                                  result.fields.excerpt,
                                                searchTerm,
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {groupedResults.pages.length > 8 && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[1] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all {groupedResults.pages.length} pages
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* Blog Posts Section */}
                              {groupedResults.blog.length > 0 && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Blog Posts</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {groupedResults.blog.length} results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {groupedResults.blog.slice(0, 8).map((result) => (
                                      <Link
                                        key={result.id}
                                        href={getResultUrl(result)}
                                        target="_blank"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(
                                              result.fields.seo?.fields?.pageTitle ||
                                                result.fields.metaSeo?.fields?.pageTitle ||
                                                result.fields.title,
                                              searchTerm,
                                            )}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <span className="line-clamp-1 text-xs text-primary-dark-500">
                                              <span className="font-bold capitalize">
                                                {result.contentType.replace("-", " ")}
                                              </span>
                                              <span> {" • "}</span>
                                              {result.fields.slug && (
                                                <span>
                                                  {result.fields.slug
                                                    .split("/")
                                                    .filter(Boolean)
                                                    .map((part, index, arr) => (
                                                      <span key={index}>
                                                        <span className="capitalize">
                                                          {highlightText(part.replace(/-/g, " "), searchTerm)}
                                                        </span>
                                                        {index < arr.length - 1 && <span className="mx-1">/</span>}
                                                      </span>
                                                    ))}
                                                </span>
                                              )}
                                            </span>
                                          </p>
                                          <div className="mb-4 flex min-h-[25px] items-center gap-2">
                                            {result.fields.category && (
                                              <span className="rounded-md bg-secondary-light-50 px-1 py-0.5 text-xs capitalize text-primary-dark-500">
                                                {result.fields.category}
                                              </span>
                                            )}
                                            {(() => {
                                              return (
                                                (result.fields.publishDate ||
                                                  result.fields.seo?.sys?.createdAt ||
                                                  result.fields.metaSeo?.sys?.createdAt) && (
                                                  <time
                                                    className="text-xs text-primary-dark-500"
                                                    dateTime={
                                                      result.fields.publishDate ||
                                                      result.fields.seo?.sys?.createdAt ||
                                                      result.fields.metaSeo?.sys?.createdAt
                                                    }
                                                  >
                                                    {new Date(
                                                      result.fields.publishDate ||
                                                        result.fields.seo?.sys?.createdAt ||
                                                        result.fields.metaSeo?.sys?.createdAt ||
                                                        new Date().toISOString(),
                                                    ).toLocaleDateString()}
                                                  </time>
                                                )
                                              );
                                            })()}
                                          </div>

                                          {(result.fields.seo?.fields?.pageDescription ||
                                            result.fields.metaSeo?.fields?.pageDescription ||
                                            result.fields.excerpt) && (
                                            <p className="text-gray-600 line-clamp-3">
                                              {highlightText(
                                                result.fields.seo?.fields?.pageDescription ||
                                                  result.fields.metaSeo?.fields?.pageDescription ||
                                                  result.fields.excerpt,
                                                searchTerm,
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {groupedResults.blog.length > 8 && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[2] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all {groupedResults.blog.length} blog posts
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* Case Studies Section */}
                              {groupedResults.cases.length > 0 && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Case Studies</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {groupedResults.cases.length} results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {groupedResults.cases.slice(0, 8).map((result) => (
                                      <Link
                                        key={result.id}
                                        href={getResultUrl(result)}
                                        target="_blank"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(
                                              result.fields.seo?.fields?.pageTitle ||
                                                result.fields.metaSeo?.fields?.pageTitle ||
                                                result.fields.title,
                                              searchTerm,
                                            )}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <span className="line-clamp-1 text-xs text-primary-dark-500">
                                              <span className="font-bold capitalize">
                                                {result.contentType.replace("-", " ")}
                                              </span>
                                              <span> {" • "}</span>
                                              {result.fields.slug && (
                                                <span>
                                                  {result.fields.slug
                                                    .split("/")
                                                    .filter(Boolean)
                                                    .map((part, index, arr) => (
                                                      <span key={index}>
                                                        <span className="capitalize">
                                                          {highlightText(part.replace(/-/g, " "), searchTerm)}
                                                        </span>
                                                        {index < arr.length - 1 && <span className="mx-1">/</span>}
                                                      </span>
                                                    ))}
                                                </span>
                                              )}
                                            </span>
                                          </p>
                                          <div className="mb-4 flex min-h-[25px] items-center gap-2">
                                            {result.fields.category && (
                                              <span className="rounded-md bg-secondary-light-50 px-1 py-0.5 text-xs capitalize text-primary-dark-500">
                                                {result.fields.category}
                                              </span>
                                            )}
                                            {(() => {
                                              return (
                                                (result.fields.publishDate ||
                                                  result.fields.seo?.sys?.createdAt ||
                                                  result.fields.metaSeo?.sys?.createdAt) && (
                                                  <time
                                                    className="text-xs text-primary-dark-500"
                                                    dateTime={
                                                      result.fields.publishDate ||
                                                      result.fields.seo?.sys?.createdAt ||
                                                      result.fields.metaSeo?.sys?.createdAt
                                                    }
                                                  >
                                                    {new Date(
                                                      result.fields.publishDate ||
                                                        result.fields.seo?.sys?.createdAt ||
                                                        result.fields.metaSeo?.sys?.createdAt ||
                                                        new Date().toISOString(),
                                                    ).toLocaleDateString()}
                                                  </time>
                                                )
                                              );
                                            })()}
                                          </div>

                                          {(result.fields.seo?.fields?.pageDescription ||
                                            result.fields.metaSeo?.fields?.pageDescription ||
                                            result.fields.excerpt) && (
                                            <p className="text-gray-600 line-clamp-3">
                                              {highlightText(
                                                result.fields.seo?.fields?.pageDescription ||
                                                  result.fields.metaSeo?.fields?.pageDescription ||
                                                  result.fields.excerpt,
                                                searchTerm,
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {groupedResults.cases.length > 8 && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[3] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all {groupedResults.cases.length} case studies
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* Integrations Section */}
                              {groupedResults.integrations.length > 0 && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Integrations</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {groupedResults.integrations.length} results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {groupedResults.integrations.slice(0, 8).map((result) => (
                                      <Link
                                        key={result.id}
                                        href={getResultUrl(result)}
                                        target="_blank"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(
                                              result.fields.seo?.fields?.pageTitle ||
                                                result.fields.metaSeo?.fields?.pageTitle ||
                                                result.fields.title,
                                              searchTerm,
                                            )}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <span className="line-clamp-1 text-xs text-primary-dark-500">
                                              <span className="font-bold capitalize">
                                                {result.contentType.replace("-", " ")}
                                              </span>
                                              <span> {" • "}</span>
                                              {result.fields.slug && (
                                                <span>
                                                  {result.fields.slug
                                                    .split("/")
                                                    .filter(Boolean)
                                                    .map((part, index, arr) => (
                                                      <span key={index}>
                                                        <span className="capitalize">
                                                          {highlightText(part.replace(/-/g, " "), searchTerm)}
                                                        </span>
                                                        {index < arr.length - 1 && <span className="mx-1">/</span>}
                                                      </span>
                                                    ))}
                                                </span>
                                              )}
                                            </span>
                                          </p>
                                          <div className="mb-4 flex min-h-[25px] items-center gap-2">
                                            {result.fields.category && (
                                              <span className="rounded-md bg-secondary-light-50 px-1 py-0.5 text-xs capitalize text-primary-dark-500">
                                                {result.fields.category}
                                              </span>
                                            )}
                                            {(() => {
                                              return (
                                                (result.fields.publishDate ||
                                                  result.fields.seo?.sys?.createdAt ||
                                                  result.fields.metaSeo?.sys?.createdAt) && (
                                                  <time
                                                    className="text-xs text-primary-dark-500"
                                                    dateTime={
                                                      result.fields.publishDate ||
                                                      result.fields.seo?.sys?.createdAt ||
                                                      result.fields.metaSeo?.sys?.createdAt
                                                    }
                                                  >
                                                    {new Date(
                                                      result.fields.publishDate ||
                                                        result.fields.seo?.sys?.createdAt ||
                                                        result.fields.metaSeo?.sys?.createdAt ||
                                                        new Date().toISOString(),
                                                    ).toLocaleDateString()}
                                                  </time>
                                                )
                                              );
                                            })()}
                                          </div>

                                          {(result.fields.seo?.fields?.pageDescription ||
                                            result.fields.metaSeo?.fields?.pageDescription ||
                                            result.fields.excerpt) && (
                                            <p className="text-gray-600 line-clamp-3">
                                              {highlightText(
                                                result.fields.seo?.fields?.pageDescription ||
                                                  result.fields.metaSeo?.fields?.pageDescription ||
                                                  result.fields.excerpt,
                                                searchTerm,
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {groupedResults.integrations.length > 8 && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[4] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all {groupedResults.integrations.length} integrations
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* Events Section */}
                              {groupedResults.events.length > 0 && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Events</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {groupedResults.events.length} results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {groupedResults.events.slice(0, 8).map((result) => (
                                      <Link
                                        key={result.id}
                                        href={getResultUrl(result)}
                                        target="_blank"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(
                                              result.fields.seo?.fields?.pageTitle ||
                                                result.fields.metaSeo?.fields?.pageTitle ||
                                                result.fields.title,
                                              searchTerm,
                                            )}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <span className="line-clamp-1 text-xs text-primary-dark-500">
                                              <span className="font-bold capitalize">
                                                {result.contentType.replace("-", " ")}
                                              </span>
                                              <span> {" • "}</span>
                                              {result.fields.slug && (
                                                <span>
                                                  {result.fields.slug
                                                    .split("/")
                                                    .filter(Boolean)
                                                    .map((part, index, arr) => (
                                                      <span key={index}>
                                                        <span className="capitalize">
                                                          {highlightText(part.replace(/-/g, " "), searchTerm)}
                                                        </span>
                                                        {index < arr.length - 1 && <span className="mx-1">/</span>}
                                                      </span>
                                                    ))}
                                                </span>
                                              )}
                                            </span>
                                          </p>
                                          <div className="mb-4 flex min-h-[25px] items-center gap-2">
                                            {result.fields.category && (
                                              <span className="rounded-md bg-secondary-light-50 px-1 py-0.5 text-xs capitalize text-primary-dark-500">
                                                {result.fields.category}
                                              </span>
                                            )}
                                            {(() => {
                                              return (
                                                (result.fields.publishDate ||
                                                  result.fields.seo?.sys?.createdAt ||
                                                  result.fields.metaSeo?.sys?.createdAt) && (
                                                  <time
                                                    className="text-xs text-primary-dark-500"
                                                    dateTime={
                                                      result.fields.publishDate ||
                                                      result.fields.seo?.sys?.createdAt ||
                                                      result.fields.metaSeo?.sys?.createdAt
                                                    }
                                                  >
                                                    {new Date(
                                                      result.fields.publishDate ||
                                                        result.fields.seo?.sys?.createdAt ||
                                                        result.fields.metaSeo?.sys?.createdAt ||
                                                        new Date().toISOString(),
                                                    ).toLocaleDateString()}
                                                  </time>
                                                )
                                              );
                                            })()}
                                          </div>

                                          {(result.fields.seo?.fields?.pageDescription ||
                                            result.fields.metaSeo?.fields?.pageDescription ||
                                            result.fields.excerpt) && (
                                            <p className="text-gray-600 line-clamp-3">
                                              {highlightText(
                                                result.fields.seo?.fields?.pageDescription ||
                                                  result.fields.metaSeo?.fields?.pageDescription ||
                                                  result.fields.excerpt,
                                                searchTerm,
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {groupedResults.events.length > 8 && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[5] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all {groupedResults.events.length} events
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* Videos Section */}
                              {drupalResults.videos?.length > 0 && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Videos</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {drupalResults.videos.length} results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {drupalResults.videos?.slice(0, 8).map((video: any) => (
                                      <Link
                                        key={video.id}
                                        href={(() => {
                                          if (video.mediaDetails?.youtubeId) {
                                            return `https://www.youtube.com/watch?v=${video.mediaDetails.youtubeId}`;
                                          }
                                          if (video.mediaDetails?.attributes?.video_url) {
                                            return video.mediaDetails.attributes.video_url;
                                          }
                                          if (video.links?.self?.href) {
                                            return video.links.self.href;
                                          }
                                          return "#"; // Fallback URL
                                        })()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(video.attributes.title, searchTerm)}
                                          </h3>
                                          <p className="text-gray-500 mb-4 text-sm">
                                            <div className="mb-2 flex items-center gap-2">
                                              <span className="rounded-md bg-secondary-light-50 px-1.5 py-0.5 text-xs font-medium text-primary-dark-500">
                                                Video
                                              </span>
                                              <time
                                                className="text-xs text-primary-dark-500"
                                                dateTime={video.attributes.created}
                                              >
                                                {new Date(video.attributes.created).toLocaleDateString()}
                                              </time>
                                            </div>
                                          </p>
                                          {/* Thumbnail */}
                                          {video.mediaDetails?.youtubeId ? (
                                            <Image
                                              src={
                                                video.mediaDetails?.thumbnailUrl
                                                  ? `https://community.appsmith.com${video.mediaDetails.thumbnailUrl}`
                                                  : `https://img.youtube.com/vi/${video.mediaDetails.youtubeId}/mqdefault.jpg`
                                              }
                                              alt={video.attributes.title}
                                              width={320}
                                              height={180}
                                              className="mb-2 h-32 w-full rounded object-cover"
                                            />
                                          ) : (
                                            <div className="bg-gray-200 mb-2 flex h-32 w-full items-center justify-center rounded">
                                              <svg
                                                className="text-gray-400 h-12 w-12"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth={2}
                                                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                                />
                                              </svg>
                                            </div>
                                          )}
                                          {/* Description */}
                                          <p className="text-gray-600 line-clamp-3">
                                            {highlightText(video.attributes.body?.value, searchTerm)}
                                          </p>
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {drupalResults.videos?.length > 8 && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[6] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all {drupalResults.videos.length} videos
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* Community Section */}
                              {(drupalResults.solutions?.length > 0 || drupalResults.templates?.length > 0) && (
                                <div className="mb-12">
                                  <div className="mb-6 flex items-center justify-between border-b border-primary-dark-200 pb-2">
                                    <h2 className="text-xl font-semibold text-primary-dark-700">Community Resources</h2>
                                    <span className="text-sm text-primary-dark-500">
                                      {(drupalResults.solutions?.length || 0) + (drupalResults.templates?.length || 0)}{" "}
                                      results
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                    {drupalResults.solutions?.slice(0, 4).map((solution: any) => (
                                      <Link
                                        key={solution.id}
                                        href={
                                          solution.attributes.path?.alias
                                            ? `https://community.appsmith.com${solution.attributes.path.alias}`
                                            : "#"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(solution.attributes.title, searchTerm)}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <div className="mb-2 flex items-center gap-2">
                                              <span className="rounded-md bg-secondary-light-50 px-1.5 py-0.5 text-xs font-medium text-primary-dark-500">
                                                Solution
                                              </span>
                                              <time
                                                className="text-xs text-primary-dark-500"
                                                dateTime={solution.attributes.created}
                                              >
                                                {new Date(solution.attributes.created).toLocaleDateString()}
                                              </time>
                                            </div>
                                          </p>
                                          <p className="text-gray-600 line-clamp-3">
                                            {highlightText(
                                              stripHtmlTags(solution.attributes.resolution?.value),
                                              searchTerm,
                                            )}
                                          </p>
                                        </div>
                                      </Link>
                                    ))}
                                    {drupalResults.templates?.slice(0, 4).map((template: any) => (
                                      <Link
                                        key={template.id}
                                        href={template.attributes.app_url?.uri || "#"}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block"
                                      >
                                        <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                          <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                            {highlightText(template.attributes.title, searchTerm)}
                                          </h3>
                                          <p className="text-gray-500 mb-2 text-sm">
                                            <div className="mb-2 flex items-center gap-2">
                                              <span className="rounded-md bg-secondary-light-50 px-1.5 py-0.5 text-xs font-medium text-primary-dark-500">
                                                Template
                                              </span>
                                              <time
                                                className="text-xs text-primary-dark-500"
                                                dateTime={template.attributes.created}
                                              >
                                                {new Date(template.attributes.created).toLocaleDateString()}
                                              </time>
                                            </div>
                                          </p>
                                          <div className="flex gap-4">
                                            <p className="text-gray-600 line-clamp-3">
                                              {highlightText(
                                                stripHtmlTags(template.attributes.description?.value),
                                                searchTerm,
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {(drupalResults.solutions?.length > 4 || drupalResults.templates?.length > 4) && (
                                    <div className="mt-4 text-center">
                                      <button
                                        onClick={() =>
                                          (document.querySelectorAll('[role="tab"]')[7] as HTMLElement)?.click()
                                        }
                                        className="hover:text-primary-light-800 inline-flex items-center gap-2 text-sm font-medium text-primary-light-700"
                                      >
                                        See all{" "}
                                        {(drupalResults.solutions?.length || 0) +
                                          (drupalResults.templates?.length || 0)}{" "}
                                        community resources
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M5 12h14" />
                                          <path d="m12 5 7 7-7 7" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* No Results and Minimum Characters Messages remain the same */}
                            </Tab.Panel>
                            <Tab.Panel>
                              <ResultsGrid
                                results={groupedResults.pages}
                                searchTerm={searchTerm}
                                isLoading={isLoading}
                              />
                            </Tab.Panel>
                            <Tab.Panel>
                              <ResultsGrid
                                results={groupedResults.blog}
                                searchTerm={searchTerm}
                                isLoading={isLoading}
                              />
                            </Tab.Panel>
                            <Tab.Panel>
                              <ResultsGrid
                                results={groupedResults.cases}
                                searchTerm={searchTerm}
                                isLoading={isLoading}
                              />
                            </Tab.Panel>
                            <Tab.Panel>
                              <ResultsGrid
                                results={groupedResults.integrations}
                                searchTerm={searchTerm}
                                isLoading={isLoading}
                              />
                            </Tab.Panel>
                            <Tab.Panel>
                              <ResultsGrid
                                results={groupedResults.events}
                                searchTerm={searchTerm}
                                isLoading={isLoading}
                              />
                            </Tab.Panel>
                            <Tab.Panel>
                              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                {drupalResults.videos?.map((video: any) => (
                                  <Link
                                    key={video.id}
                                    href={(() => {
                                      if (video.mediaDetails?.youtubeId) {
                                        return `https://www.youtube.com/watch?v=${video.mediaDetails.youtubeId}`;
                                      }
                                      if (video.mediaDetails?.attributes?.video_url) {
                                        return video.mediaDetails.attributes.video_url;
                                      }
                                      if (video.links?.self?.href) {
                                        return video.links.self.href;
                                      }
                                      return "#"; // Fallback URL
                                    })()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block"
                                  >
                                    <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                      <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                        {highlightText(video.attributes.title, searchTerm)}
                                      </h3>
                                      <p className="text-gray-500 mb-4 text-sm">
                                        <div className="mb-2 flex items-center gap-2">
                                          <span className="rounded-md bg-secondary-light-50 px-1.5 py-0.5 text-xs font-medium text-primary-dark-500">
                                            Video
                                          </span>
                                          <time
                                            className="text-xs text-primary-dark-500"
                                            dateTime={video.attributes.created}
                                          >
                                            {new Date(video.attributes.created).toLocaleDateString()}
                                          </time>
                                        </div>
                                      </p>
                                      {/* Thumbnail */}
                                      {video.mediaDetails?.youtubeId ? (
                                        <Image
                                          src={
                                            video.mediaDetails?.thumbnailUrl
                                              ? `https://community.appsmith.com${video.mediaDetails.thumbnailUrl}`
                                              : `https://img.youtube.com/vi/${video.mediaDetails.youtubeId}/mqdefault.jpg`
                                          }
                                          alt={video.attributes.title}
                                          width={320}
                                          height={180}
                                          className="mb-2 h-32 w-full rounded object-cover"
                                        />
                                      ) : (
                                        <div className="bg-gray-200 mb-2 flex h-32 w-full items-center justify-center rounded">
                                          <svg
                                            className="text-gray-400 h-12 w-12"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                            />
                                          </svg>
                                        </div>
                                      )}
                                      {/* Description */}
                                      <p className="text-gray-600 line-clamp-3">
                                        {highlightText(video.attributes.body?.value, searchTerm)}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </Tab.Panel>
                            <Tab.Panel>
                              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                                {drupalResults.solutions?.map((solution: any) => (
                                  <Link
                                    key={solution.id}
                                    href={
                                      solution.attributes.path?.alias
                                        ? `https://community.appsmith.com${solution.attributes.path.alias}`
                                        : "#"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block"
                                  >
                                    <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                      <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                        {highlightText(solution.attributes.title, searchTerm)}
                                      </h3>
                                      <p className="text-gray-500 mb-2 text-sm">
                                        <div className="mb-2 flex items-center gap-2">
                                          <span className="rounded-md bg-secondary-light-50 px-1.5 py-0.5 text-xs font-medium text-primary-dark-500">
                                            Solution
                                          </span>
                                          <time
                                            className="text-xs text-primary-dark-500"
                                            dateTime={solution.attributes.created}
                                          >
                                            {new Date(solution.attributes.created).toLocaleDateString()}
                                          </time>
                                        </div>
                                      </p>
                                      <p className="text-gray-600 line-clamp-3">
                                        {highlightText(
                                          stripHtmlTags(solution.attributes.resolution?.value),
                                          searchTerm,
                                        )}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                                {drupalResults.templates?.map((template: any) => (
                                  <Link
                                    key={template.id}
                                    href={template.attributes.app_url?.uri || "#"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block"
                                  >
                                    <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
                                      <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
                                        {highlightText(template.attributes.title, searchTerm)}
                                      </h3>
                                      <p className="text-gray-500 mb-2 text-sm">
                                        <div className="mb-2 flex items-center gap-2">
                                          <span className="rounded-md bg-secondary-light-50 px-1.5 py-0.5 text-xs font-medium text-primary-dark-500">
                                            Template
                                          </span>
                                          <time
                                            className="text-xs text-primary-dark-500"
                                            dateTime={template.attributes.created}
                                          >
                                            {new Date(template.attributes.created).toLocaleDateString()}
                                          </time>
                                        </div>
                                      </p>
                                      <div className="flex gap-4">
                                        <p className="text-gray-600 line-clamp-3">
                                          {highlightText(
                                            stripHtmlTags(template.attributes.description?.value),
                                            searchTerm,
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </Tab.Panel>
                          </>
                        )}
                      </div>
                    </Tab.Panels>
                  </Tab.Group>
                ) : (
                  <div className="mt-4">
                    <ResultsGrid results={searchResults} searchTerm={searchTerm} isLoading={isLoading} />
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </>
  );
};

// Create a separate component for the results grid
const ResultsGrid: React.FC<{
  results: SearchResult[];
  searchTerm: string;
  isLoading: boolean;
}> = ({ results, searchTerm, isLoading }) => {
  if (isLoading) {
    return <SearchGridSkeleton />;
  }

  if (results.length === 0 && searchTerm && searchTerm.length >= 3) {
    return (
      <div className="text-gray-600 col-span-4 py-8 text-center">
        <p className="text-lg">No results found for &ldquo;{searchTerm}&rdquo;</p>
        <p className="mt-2 text-sm">Try different keywords or check your spelling</p>
      </div>
    );
  }

  if (searchTerm && searchTerm.length < 3) {
    return (
      <div className="col-span-4 py-8 text-center">
        <p className="text-gray-600 text-sm">Please enter at least 3 characters to search.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
      {results.map((result) => (
        <Link key={result.id} href={getResultUrl(result)} target="_blank" className="block">
          <div className="group relative border-b border-primary-dark-100/60 px-4 py-5 transition-all duration-300 before:absolute before:inset-x-0 before:bottom-0 before:h-[1px] before:w-0 before:bg-primary-light-700 before:transition-all before:duration-300 hover:bg-primary-light-700/[0.02] hover:before:w-full">
            <h3 className="mb-1 line-clamp-2 text-lg font-semibold hover:text-primary-light-700">
              {highlightText(
                result.fields.seo?.fields?.pageTitle || result.fields.metaSeo?.fields?.pageTitle || result.fields.title,
                searchTerm,
              )}
            </h3>
            <p className="text-gray-500 mb-2 text-sm">
              <span className="line-clamp-1 text-xs text-primary-dark-500">
                <span className="font-bold capitalize">{result.contentType.replace("-", " ")}</span>
                <span> {" • "}</span>
                {result.fields.slug && (
                  <span>
                    {result.fields.slug
                      .split("/")
                      .filter(Boolean)
                      .map((part, index, arr) => (
                        <span key={index}>
                          <span className="capitalize">{highlightText(part.replace(/-/g, " "), searchTerm)}</span>
                          {index < arr.length - 1 && <span className="mx-1">/</span>}
                        </span>
                      ))}
                  </span>
                )}
              </span>
            </p>
            <div className="mb-4 flex min-h-[25px] items-center gap-2">
              {result.fields.category && (
                <span className="rounded-md bg-secondary-light-50 px-1 py-0.5 text-xs capitalize text-primary-dark-500">
                  {result.fields.category}
                </span>
              )}
              {(() => {
                return (
                  (result.fields.publishDate ||
                    result.fields.seo?.sys?.createdAt ||
                    result.fields.metaSeo?.sys?.createdAt) && (
                    <time
                      className="text-xs text-primary-dark-500"
                      dateTime={
                        result.fields.publishDate ||
                        result.fields.seo?.sys?.createdAt ||
                        result.fields.metaSeo?.sys?.createdAt
                      }
                    >
                      {new Date(
                        result.fields.publishDate ||
                          result.fields.seo?.sys?.createdAt ||
                          result.fields.metaSeo?.sys?.createdAt ||
                          new Date().toISOString(),
                      ).toLocaleDateString()}
                    </time>
                  )
                );
              })()}
            </div>

            {(result.fields.seo?.fields?.pageDescription ||
              result.fields.metaSeo?.fields?.pageDescription ||
              result.fields.excerpt) && (
              <p className="text-gray-600 line-clamp-3">
                {highlightText(
                  result.fields.seo?.fields?.pageDescription ||
                    result.fields.metaSeo?.fields?.pageDescription ||
                    result.fields.excerpt,
                  searchTerm,
                )}
              </p>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SearchModal;
